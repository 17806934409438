// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
// jquery
import "jquery"
// popper
import "@popperjs/core"
//bootstrap
import "bootstrap"

console.log('Hello World from Webpacker frontend.js')
